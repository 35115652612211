import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { usePagination } from '@/commons/hooks/pagination/use-pagination'
import { PaginationStateKeys } from '@/commons/stores/pagination/pagination'

export default function useItemList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'codigo_proposta',
      label: 'Proposta',

    },
    {
      key: 'nome',
      label: 'nome',
      class: 'font-size-8',
    },
    {
      key: 'itens',
      label: 'disciplinas',
    },
    {
      key: 'tipo_custo',
      label: 'Subdisciplinas',
    },
    {
      key: 'valor',
      label: 'Valor',
      class: 'text-center',
      sortable: true,
      thStyle: { width: '20%' },

    },
    {
      key: 'data_entrega',
      label: 'Entrega',
      class: 'text-center',
    },
    {
      key: 'status',
      label: 'Status',
      class: 'text-center',
    },
    {
      key: 'Ações',
      class: 'text-center',
    },
  ]

  const filters = ref({})

  const {
    perPage,
    totalItens,
    currentPage,
    perPageOptions,
    searchQuery,
    isSortDirDesc,
    sortBy,
  } = usePagination({
    id: PaginationStateKeys.GRID_PROPOSTAS,
    sortBy: 'id',
    isSortDirDesc: true,
  })

  const dataMeta = computed(() => {
    // eslint-disable-next-line no-unused-expressions
    currentPage.value - 1

    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItens.value,
    }
  })

  const getFilters = () => {
    Object.keys(filters.value).forEach((key) => {
      if (!filters.value[key]) {
        delete filters.value[key]
      }
    })
  }

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], (newVal, oldVal) => {
    refetchData()
  })

  const fetchDados = (ctx, callback) => {
    getFilters()
    store
      .dispatch('propostas/fetchDados', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        ...filters.value,
      })
      .then((response) => {
        callback(response.data)
        totalItens.value = response.data.totalItens
      })
  }

  const deleteItem = (id) => {
    store.dispatch('propostas/deleteItem', id).then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Proposta excluída com sucesso',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      refetchData()
    })
  }
  const create = (item) => {
    store.dispatch('propostas/store', item).then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Proposta criada com sucesso',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      router.push({ name: 'propostas' })
    })
  }
  const update = (item) => {
    store.dispatch('propostas/update', item).then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Proposta editada com sucesso ',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      router.push({ name: 'propostas' })
    })
  }

  const alterarStatus = (item) => {
    store.dispatch('propostas/alterarStatus', item).then(() => {
      window.history.back()

      toast({
        component: ToastificationContent,
        props: {
          title: 'Status alterado com sucesso ',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    })
  }

  const getItem = (id) => {
    return store.dispatch('propostas/getItem', id)
  }

  return {
    fetchDados,
    deleteItem,
    tableColumns,
    perPage,
    currentPage,
    totalItens,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    filters,
    refUserListTable,
    refetchData,
    update,
    alterarStatus,
    create,
    getItem,
  }
}
